.calendar-outer-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .calendar-inner-container {
    width: 100%;
  }
  
  .custom-calendar {
    width: 100%;
    background: white;
    border: 2px solid #2196f3;
    border-radius: 8px;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  /* Navigation area styling */
  .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .react-calendar__navigation button {
    background: none;
    border: none;
    font-size: 20px;
    padding: 5px 10px;
    cursor: pointer;
    color: #666;
  }
  
  .react-calendar__navigation button:disabled {
    opacity: 0.3;
  }
  
  .react-calendar__navigation button:hover:enabled {
    color: #2196f3;
  }
  
  /* Month view styling */
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 14px;
    color: #666;
  }
  
  .react-calendar__month-view__weekdays__weekday {
    padding: 10px;
  }
  
  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
  }
  
  /* Day tiles styling */
  .react-calendar__tile {
    background: none;
    border: none;
    padding: 10px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    height: 40px;
  }
  
  .react-calendar__tile:enabled:hover {
    background-color: #f5f5f5;
  }
  
  .react-calendar__tile--active {
    background-color: #e3f2fd;
    color: #2196f3;
  }
  
  .react-calendar__tile:disabled {
    background-color: #f5f5f5;
    color: #ccc;
    cursor: not-allowed;
  }
  
  /* Navigation label (month/year) styling */
  .react-calendar__navigation__label {
    font-size: 16px;
    color: #333;
    font-weight: normal;
  }
  
  /* Remove default button focus outline */
  button:focus {
    outline: none;
  }
  
  /* Today's date styling */
  .react-calendar__tile--now {
    background: #fff;
    font-weight: bold;
  }
  
  .react-calendar__month-view__days__day--weekend {
    color: #666;
  }
  
  /* Make sure calendar is responsive */
  @media screen and (max-width: 768px) {
    .calendar-inner-container {
      width: 90%;
    }
  }
  
  @media screen and (max-width: 480px) {
    .calendar-inner-container {
      width: 100%;
    }
  }