.cart-container {
  max-width: 500px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto 1fr;
}

.cart-header {
  padding: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.cart-header h2 {
  margin: 0;
  font-size: 1.25rem;
  color: #1f2937;
  font-weight: 600;
}

.cart-content {
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 1.5rem;
  padding: 1.5rem;
  min-height: 400px;
}

.promo-section {
  background: #f9fafb;
  border-radius: 8px;
  padding: 1rem;
  display: grid;
  gap: 0.75rem;
}

.promo-row {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 1rem;
}

.promo-label {
  color: #4b5563;
  font-size: 0.875rem;
}

.promo-badge {
  background: #fdf2f8;
  color: #be185d;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-weight: 500;
}

.promo-value {
  color: #be185d;
  font-weight: 500;
}

.cart-items {
  display: grid;
  gap: 0.75rem;
  overflow-y: auto;
  max-height: 280px;
  padding-right: 0.5rem;
}

.cart-items::-webkit-scrollbar {
  width: 4px;
}

.cart-items::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.cart-items::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.cart-item {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: #f9fafb;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.cart-item:hover {
  background: #f3f4f6;
}

.item-name {
  color: #374151;
  font-weight: 500;
}

.item-amount {
  color: #be185d;
  font-weight: 600;
}

.cart-totals {
  border-top: 1px solid #e5e7eb;
  padding-top: 1rem;
  display: grid;
  gap: 0.75rem;
}

.total-row {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 1rem;
}

.total-label {
  color: #4b5563;
  font-size: 0.875rem;
}

.total-value {
  color: #1f2937;
  font-weight: 600;
}

.total-row.final {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #e5e7eb;
}

.total-value.discount {
  color: #be185d;
  font-size: 1.125rem;
  font-weight: 700;
}

@media (max-width: 500px) {
  .cart-container {
    margin: 0 1rem;
    border-radius: 8px;
  }
}

.doctor-profile {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  gap: 2rem;
  margin-bottom: 2rem;
}

.doctor-profile__about {
  /* Additional styles for the about section can be added here */
}

.doctor-profile__name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;
}

.doctor-profile__heading {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1rem;
}

.doctor-profile__description {
  color: #4b5563;
  line-height: 1.625;
}

.doctor-profile__qualifications {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  gap: 1.5rem;
  margin-top: 25px;
}

.doctor-profile__qualifications-list {
  display: flex;
  /* flex-direction: column; */
  gap: 1rem;
}

.doctor-profile__qualification-item {
  width: 100%;
  padding: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background: #ffffff;
}

.doctor-profile__label {
  font-weight: 600;
  color: #4b5563;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-right: 2px;
}

.doctor-profile__value {
  color: #6b7280;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .doctor-profile {
    grid-template-columns: 1fr; /* Stack columns on smaller screens */
    gap: 1rem; /* Adjust gap for smaller screens */
  }

  .doctor-profile__name {
    font-size: 1.25rem;
  }

  .doctor-profile__heading {
    font-size: 1rem;
  }

  .doctor-profile__qualifications-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.appointment-container {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  gap: 2rem;
}

.appointment-details {
  flex: 7;
  display: flex;
  flex-direction: column;
}

.calendar-and-fee {
  gap: 2rem;
  width: 100%;
}

.fee-and-slots {
  margin-bottom: 2rem;
}

.service-type {
  margin-bottom: 2rem;
}

.home-service {
  display: flex;
  gap: 20px; /* Adjust spacing as needed */
  margin-bottom: 1rem;
}

.promo-section {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.cart-container {
  flex: 4;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.cart-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.5rem;
}

.cart-items {
  overflow-y: auto;
  max-height: 280px; /* Adjust as needed */
}

.cart-item {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: #f9fafb;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.cart-item:hover {
  background: #f3f4f6;
}

.cart-totals {
  border-top: 1px solid #e5e7eb;
  padding-top: 1rem;
}

.final {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #e5e7eb;
}

/* Responsive styles */
@media (max-width: 768px) {
  .appointment-container {
    flex-direction: column; /* Stack on smaller screens */
  }

  .appointment-details,
  .cart-container {
    width: 100%; /* Full width on smaller screens */
  }

  .home-service {
    flex-direction: column; /* Stack inputs for home service */
  }

  .promo-section {
    flex-direction: column; /* Stack promo inputs */
  }
}

@media (max-width: 500px) {
  .cart-container {
    margin: 0 1rem;
  }
}
