/* DoctorsCard.css */
.doctors-cards-container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  gap: 20px; /* Reduced space between cards */
  padding: 20px;
}

.blood-test-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 300px; /* Set a max width for the cards */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-title {
  font-size: 1.2em;
  font-weight: bold;
}

.rating-container {
  display: flex;
  align-items: center;
}

.rating-text {
  margin-left: 5px;
  font-size: 1em;
  color: #fbbf24; /* Star color */
}

.card-description {
  font-size: 0.9em;
  margin: 10px 0;
  color: #666;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.price-container {
  display: flex;
  align-items: baseline;
}

.currency {
  font-size: 1.2em;
  font-weight: bold;
  color: #c41f5b;
}

.price {
  font-size: 1.2em;
  font-weight: bold;
  margin-left: 5px;
  color: #c41f5b;
}

.select-button {
  background-color: #090446;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.select-button.selected {
  background-color: #4caf50; /* Color for selected button */
}

.select-button:disabled {
  background-color: grey; /* Color for disabled button */
  cursor: not-allowed; /* Change cursor to indicate it’s disabled */
}


/* .select-button:hover {
  background-color: #090446;
} */
