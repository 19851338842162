@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Roboto";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slider-container .slick-slide > div {
  /* margin: 20px; */
  /* margin-left: -9px !important; */
}

.doc-card-skeleton {
  margin: 10px;
  background-color: white;
  border-radius: 8px;
  padding: 10px 12px;
  min-width: 100% !important;
  max-width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 4px 4px 0px #00000040;
  transition: transform 0.3s ease;
  cursor: pointer;
  font-family: "Roboto";
}

.doc-card {
  margin: 10px;
  background-color: white;
  border-radius: 8px;
  padding: 10px 4px;
  min-width: 250px !important;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 4px 4px 0px #00000040;
  transition: transform 0.3s ease;
  cursor: pointer;
  font-family: "Roboto";
}

.doc-carousel .doc-card {
  min-width: 260px !important;
}

.doc-card:hover {
  transform: translateY(-5px);
  /* Adjust the amount of movement as needed */
}

.doc-img-box {
  position: relative;
}

.doc-tag {
  position: absolute;
  bottom: 10px;
  left: 20px;
  padding: 4px 8px;
  border-radius: 5px;
  /* height: 15px; */
  background-color: #ffff;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  letter-spacing: 0.71px;
  gap: 3px;
}

.doc-tag span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.doc-img {
  width: 220px;
  height: 220px;
  border-radius: 10px;
  margin: 0 auto;
  object-fit: cover;
}

.doc-name {
  text-align: center;
}

.doc-name h2 {
  margin: 0;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-top: 10px;
  font-family: "Roboto";
}

/* .doc-name div span {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 14px;
  font-size: 12px;
  margin: 0;
  font-weight: 400;
} */

/* .doc-name div {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.grey-text {
  color: #979797;
  font-size: small;
  font-weight: 400;
  font-family: "Roboto";
}

.rating {
  font-size: 12px !important;
  line-height: 14px !important;
  font-weight: 700 !important;
}

.rate-div {
  font-size: 12px !important;
  margin: 5px;
}

.rating svg {
  margin: 0 4px;
}

.doc-detail {
  display: flex;
  justify-content: center !important;
  padding: 5px;
  margin: 20px 0 0;
  font-family: "Roboto";
  gap: 30px;
}

.doc-detail .detail {
  font-weight: 700;
  padding: 10px 4px;
}

.doc-detail p {
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  font-family: "Roboto";
}

.doc-book-btn {
  margin: 20px 0 10px;
  display: flex;
  justify-content: center;
}

.doc-book-btn button {
  width: 85%;
  background-color: #090446;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.doc-book-btn button span {
  margin: 0 10px;
  font-family: "Roboto";
}

.doc-next-arr {
  display: flex;
  top: calc(50% - 60px);
  right: -40px !important;
  position: absolute;
  margin: 0 auto;
  /* border: 1px solid #007bff; */
  /* Border color can be adjusted */
  background-color: #090446;
  /* Background color can be adjusted */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  /* Text color can be adjusted */
  cursor: pointer;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

/* .doc-next-arr:hover {
  background-color: #007bff;
  color: #fff;
} */

.doc-prev-arr {
  display: flex;
  top: calc(50% - 60px);
  left: -40px !important;
  position: absolute;
  margin: 0 auto;
  /* Border color can be adjusted */
  background-color: #090446;
  /* Background color can be adjusted */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  /* Text color can be adjusted */
  cursor: pointer;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.filter-wrapper {
  display: flex;
  padding: 10px 10px;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 1100px;
  margin: 0 auto 10px;
  align-items: center;
  /* overflow-y: auto; */
  position: relative;
}

.find-doc-page .filter-wrapper {
  /* height: 176px; */
  padding-bottom: 60px;
}

.filter-view-more {
  /* position: absolute; */
  left: calc(50% - 25px);
  bottom: 0;
  cursor: pointer;
  /* font-size: 46px; */
  /* background-color: #090446; */
  /* color: white; */
  /* border-radius: 23px; */
  /* height: 45px; */
  /* width: 45px; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px; */
  display: flex;
}

.filter-modal-main {
  padding-top: 20px;
}

.filter-modal-tab-warpper {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.filter-modal-search {
  margin: 10px 0;
  display: flex;
}

.filter-modal-search input:focus {
  outline: none;
}

.filter-modal-search input {
  width: 100%;
  border-radius: 4px;
  border: #090446 solid 2px;
  padding: 10px 10px;
}

.filter-wrapper::-webkit-scrollbar {
  width: 5px;
  /* width of the scrollbar */
}

.filter-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* color of the track */
}

.filter-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  /* color of the thumb */
  border-radius: 2px;
  /* roundness of the thumb */
}

.filter-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* color of the thumb on hover */
}

.filter-tab {
  border: #090446 1px solid;
  transition: background-color 0.3s ease;
  min-width: 100px;
  height: 49px;
  flex: 1;
  padding: 0px 10px;
  border-radius: 5px;
  color: white;
  background-color: #090446;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.active-filter-tab {
  border: #fff 1px solid;
  background-color: #c2095a;
  color: #fff;
  font-size: 14px;
  font-weight: 900;
  line-height: 17px;
}

.filter-tab:hover {
  border: #fff 1px solid;
  background-color: #c2095a;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

.filter-tab.active:hover {
  border: #fff 1px solid;
  background-color: #090446;
  color: #fff;
  font-size: 14px;
  font-weight: 900;
  line-height: 17px;
}

.filter-view-all a {
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 4px;
}

.filter-modal-main .filter-tab {
  min-width: 130px;
}

.find-doc-wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 800px) {
  .find-doc-wrapper {
    justify-content: center;
  }

  .filter-wrapper {
    justify-content: center;
  }
}

/* book modal css */
.book-main {
  max-width: 600px;
  padding: 0 10px;
  font-family: "Roboto";
}

.book-img-box {
  max-width: 700px;
}

.book-img-box .book-img {
  border-radius: 10px;
  max-width: 600px;
  margin-top: 40px;
  width: 100%;
  max-height: 330px;
}

.book-isssssmg {
  border-radius: 10px;
  margin-top: 40px;
  width: 100%;
  max-height: 580px;
}

.book-detail {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.book-detail .book-name h1 {
  margin: 10px 0 0;
  font-weight: 600;
  font-size: 36px;
  font-family: "Roboto";
  line-height: 40px;
}

.book-detail .book-name p {
  margin: 0;
  font-size: 20px;
  color: #979797;
  font-family: "Roboto";
}

/* 
.book-detail .book-name .rating {
  font-weight: 700;
  color: #000;
  font-size: 15px !important;
  font-family: "Roboto";
} */

.book-exp {
  padding-top: 10px;
  font-family: "Roboto";
}

.book-exp p {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  font-family: "Roboto";
}

.book-about {
  /* margin-top: 20px; */
}

.book-about h2 {
  font-size: 15px;
  font-weight: 800;
  line-height: 18px;
  font-family: "Roboto";
}

.book-about p {
  font-size: 15px;
  font-weight: 400;
  font-family: "Roboto";
}

.book-available {
  /* margin-top: 40px; */
}

.book-available h2 {
  font-size: 15px;
  font-weight: 800;
  line-height: 18px;
  font-family: "Roboto";
}

.book-calendar {
  max-width: 310px;
}

.book-calendar .react-calendar {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border: none;
  padding: 10px;
}

.book-calendar .react-calendar__navigation {
  background-color: #f1f3ff;
  border-radius: 22px;
}

.book-calendar .react-calendar__navigation span {
  font-weight: 600;
  color: #090446;
}

.book-calendar .react-calendar__month-view__weekdays abbr {
  text-decoration: none;
  color: #979797;
}

.book-calendar
  .react-calendar__month-view__days
  .react-calendar__month-view__days__day
  abbr {
  font-weight: 600;
  color: #000;
  font-size: 15px;
}

.book-calendar
  .react-calendar__month-view__days
  .react-calendar__month-view__days__day.react-calendar__tile--active
  abbr {
  color: #fff;
}

.book-calendar
  .react-calendar__month-view__days
  .react-calendar__month-view__days__day.react-calendar__tile--active {
  border-radius: 8px;
  color: #fff;
}

.book-calendar .react-calendar__tile:disabled {
  background-color: #b9daff;
  color: #f1f3ff;
}

.book-calendar .react-calendar__tile:disabled abbr {
  color: #7e7e7e;
}

.book-form {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.form-input-container {
  width: 100%;
  padding-right: 10px;
  padding-top: 10px;
  color: #afafaf;
  font-size: 13px;
  font-weight: 500;
}

.form-input {
  border-radius: 5px;
  border: #090446 1px solid;
  padding: 10px;
  margin: 10px 0;
}

.book-ant-select {
  height: 44px;
}

.book-ant-select .ant-select-selector {
  width: 100% !important;
  border: #090446 1px solid !important;
  /* padding: 10px 10px !important; */
  /* height: 30px; */
}

.form-input-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.book-appoinment-btn {
  margin: 5px 0 10px;
  display: flex;
  justify-content: center;
}

.book-appoinment-btn a {
  width: 100%;
}

.book-appoinment-btn button {
  width: 100%;
  background-color: #090446;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  gap: 4px;
}

/* book modal css */

@media (max-width: 1024px) {
  #react-app {
    max-width: 86% !important;
  }
}

.appointments-descriptions {
  padding: 1%;
}

/* Appointment.css */
.appointment-container {
  max-width: 750px;
  margin: -6px auto;
  padding: 20px;
  font-family: "Roboto";
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border: 5px solid #000000;
  color: #090446;
}

.appointment-container.login {
  max-width: 600px;
}

.appointment-container h1 {
  font-family: "Roboto";
  color: #090446;
}

.appointment-container img {
  max-height: 300px;
}

.appointment-form {
  margin-top: 20px;
}

.appointment-form-group {
  margin-bottom: 20px;
  padding: 0 22px 0 0px;
}

.appointment-form label {
  display: block;
  margin-bottom: 5px;
}

.appointment-form input[type="text"],
.appointment-form input[type="tel"],
.appointment-form input[type="email"],
.appointment-form input[type="date"],
.appointment-form textarea,
.appointment-form input[type="password"] {
  width: 100%;
  padding: 18px 10px;
  font-size: 16px;
  border: 1px solid #090446;
  border-radius: 5px;
}

.appointment-form-group a {
  text-decoration: none;
  color: #000;
}

.appointment-form-group a:hover {
  color: #0056b3;
}

.appointment-form {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.appointment-form.login {
  display: block;
}

.appointment-form-group {
  flex: 1 1 calc(45% - 10px);
  /* Adjust width for two inputs per line */
  margin-bottom: 20px;
}

.appointment-form-group label {
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .appointment-form-group {
    flex: 1 1 100%;
    /* Set width for one input per line */
  }

  .search-doc {
    justify-content: center !important;
  }
}

.appointment-submit-btn {
  padding: 20px 40px;
  width: 100%;
  font-size: 16px;
  background-color: #090446;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto;
}

.appointment-submit-btn:hover {
  background-color: #0056b3;
}

.login-navigate p {
  margin-top: 20px;
  font-weight: 400;
}

.login-navigate span {
  font-weight: 700;
  color: #c2095a;
  cursor: pointer;
}

.heading-register {
  margin: 0 auto;
  text-align: center;
  font-size: 40px;
  font-weight: 900;
}

.social-heading {
  font-weight: 700;
}

.social-book-icon-main {
  display: flex;
  gap: 20px;
}

.social-book-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  box-shadow: -1px 4px 16.9px 0px #00000014 !important;
  padding: 14px 10px !important;
  width: 100%;
  background-color: #fff;
  border: none;
}

.social-book-icon span {
  font-size: 13px;
  font-weight: 500;
}

.social-book-icon.facebook svg {
  color: #0056b3;
  font-size: 20px;
}

.social-book-icon svg {
  font-size: 20px;
}

/* SEARCH DOCTOR */
.search-doc {
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  margin: 0 auto 20px;
  flex-wrap: wrap;
  gap: 10px;
}

.search-form-group .book-ant-select {
  min-width: 240px !important;
  height: 47px;
}

.search-form-group .book-ant-select .ant-select-selection-placeholder {
  font-weight: 400;
}

input:focus {
  outline: none !important;
}

.search-form-group input[type="text"],
.search-form-group input[type="date"],
.search-form-group input[type="email"],
.search-form-group input[type="datetime-local"] {
  /* width: 100%; */
  padding: 10px 10px;
  font-size: 16px;
  border: 1px solid #090446;
  border-radius: 5px;
  min-width: 239px;
}

.search-form-btn .appointment-submit-btn {
  padding: 10px 25px 5px;
}

textarea:focus {
  outline: none;
}

.appointments-descriptions {
}

.logo {
  width: 211px;
  height: 70px;
  background: url("https://portal.eclinicasia.com/email/images/v474_520.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 5px auto;
}

.search-form-btn {
  display: flex;
  gap: 10px;
  /* padding-left: 22px; */
}

.ant-select-selector {
  font-size: 13px;
  font-weight: 800;
}

/* SEARCH DOCTOR */

.forget-pass-btn {
  margin: 10px 0;
}

.forget-pass-btn a {
  text-decoration: none;
  color: #090446;
}
