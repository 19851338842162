.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.otp-input {
  padding: 18px 12px;
  font-size: 30px;
  border: 1px solid #090446;
  border-radius: 10px;
  max-width: 40px;
  outline: none;
  margin: 10px;
  text-align: center;
  min-width: 70px;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media screen and (max-width: 1100px) {


  .otp-input {
    padding: 15px 3px;
    font-size: 22px;
    border: 1px solid #090446;
    border-radius: 10px;
    max-width: 40px;
    outline: none;
    margin: 5px;
    text-align: center;
    min-width: 20px;
  }

}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}